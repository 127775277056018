<template>
  <section
    class="view-configure-document"
    id="drag-template-background"
    v-loading="documentDataLoading"
  >
    <div>
      <div class="document-corrected-container">
        <img
          :src="require('@/assets/img/icons/document_corrected.svg')"
          style="width: 100px; margin-top: 200px"
        />
        <h1 style="color: red">Link Expired</h1>
        <h1>Provided Document Link got Expired</h1>

        <el-button
          v-if="!documentLinkResendStatus"
          type="text"
          @click="requestNewLink"
          icon="el-icon-refresh"
        >Request New Link </el-button>

        <el-card
          class="box-card"
          v-if="documentLinkResendStatus"
        >
          <span> New Link Sent To Your Email [{{documentLinkResendEmail || '-'}}]</span>

          <el-button
            type="text"
            @click="requestNewLink"
            icon="el-icon-finished"
          >Resend Email</el-button>
        </el-card>

      </div>
    </div>

  </section>
</template>

<script>
import tokenExpiryHelper from "@/mixins/tokenExpiryHelper";
// import config from "../../../config/app";
export default {
  name: "EmpDoc-DocumentPreview-Expired",
  components: {},
  mixins: [tokenExpiryHelper],
  data() {
    return {
      documentName: "",
      documentDataLoading: false,
      documentLinkResendStatus: false,
      documentLinkResendEmail: ""
    };
  },
  async created() {},
  async mounted() {},
  computed: {},
  methods: {
    async requestNewLink() {
      let params = {
        ...this.$route.params,
        ...this.$route.query
      };
      this.documentDataLoading = true;

      params.id = params.employee_document_id;
      params.contact_id = params.user_id;

      let { status, email } = await this.requestNewDocumentLink(params);
      this.documentLinkResendStatus = status;
      this.documentLinkResendEmail = email;

      this.documentDataLoading = false;
    }
  },
  beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
.document-corrected-container {
  font-family: "Inter", sans-serif;
  text-align: center;
  height: 630px;
  .corrected-heading {
    font-weight: 500;
    font-size: 22px;
    line-height: 39px;
    color: #212529;
  }
  .corrected-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 39px;
    color: #303133;
  }
  .correct-content {
    font-weight: 400;
    font-size: 18px;
    line-height: 39px;
    color: #303133;
  }
}
.logo {
  margin-top: 10px;
  width: 150px;
  //   height: 40px !important;
}
.document-root {
  position: relative;
  height: calc(100vh - 90px);
  overflow: hidden;
  width: 950px;
  margin: 10px auto;
  .form-field-options {
    position: sticky;
    bottom: 0px;
    margin: 10px auto 0;
    padding: 5px 0px;
    background-color: #ffffff;
    border-top: 1px solid #efefef;
  }
  .each-stat {
    margin-right: 1em;
  }
  .field-stats-block {
    p {
      line-height: 40px;
    }
    .count {
      margin-right: 0.5em;
      background-color: #ffffff;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    }
  }
}
.form-builder {
  width: 950px;
  max-height: 900px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #efefef;
  -webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
  box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  .form-image-holder {
    width: 100%;
    img {
      width: 100%;
    }
  }

  .form-fields-holder {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .each-element img {
      width: 100%;
    }
  }
}
#drag-template-background {
  .img-fluid {
    height: auto !important;
  }
  .inner-navbar {
    // position: fixed;
    // top: 0;
    // left: 0;
    // right: 0;
    z-index: 100;
    background-color: #1b487e;
    border: 1px solid #1b487e;
    margin-bottom: 0 !important;
    & > .d-flex {
      gap: 10px;
      height: 60px;
      padding: 0 10px;
      align-items: center;
      .back-btn {
        height: inherit;
      }
    }
    .left-block {
      .el-input__inner {
        min-width: 250px;
        max-width: 300px;
        background: #fff;
        height: 47px;
      }
    }
    .expiry-date {
      border-radius: 20px;
      padding: 0px 10px;
      background: #f754a2;
      color: #ffffff;
      font-weight: 600;
      font-size: 0.91em;
      border-color: #f754a2;
    }
    .manage-recipients {
      padding: 0;
      background-color: transparent;
      border: none;
      .el-tag {
        font-size: 1em;
        border-radius: 20px;
        padding: 0px;
        line-height: 40px;
        height: 40px;
        width: 40px;
        margin-left: -0.35em;
        border: 1px solid #1b487e;
      }
    }
  }
  .document-preview-block {
    position: relative;
    // top: 62px;
    // display: grid;
    // justify-content: center;
    .activity-block {
      background-color: #ffffff;
      // border-left: 1px solid #efefef;
      // Tags
      .el-tabs {
        box-shadow: none;
        min-height: calc(100vh - 62px);
      }
    }
    td {
      padding-top: 0;
      vertical-align: top;
      &:nth-child(1) {
        width: 20%;
      }
      &:nth-child(2) {
        width: 80%;
      }
    }
    .inputs-list {
      background: #fff;
      padding: 15px;
      box-shadow: 0 1px 5px 0px rgba(0, 0, 0, 0.06),
        0 1px 0px rgba(0, 0, 0, 0.08);
      border-radius: 5px;
      .el-button {
        border: none;
        background: rgba(236, 245, 255, 0.7);
        &:hover {
          background: rgba(236, 245, 255, 1);
        }
      }
      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
  .form-image {
    max-width: 770px;
    min-height: 1000px;
    background-color: #e6dcdc;
  }
  .el-input__inner {
    display: inline !important;
  }

  .form-holder {
    width: 100%;
    position: relative;
    .el-input__inner,
    .el-textarea__inner {
      height: 100% !important;
      min-height: 30px !important;
      line-height: inherit !important;
    }
    input[type="checkbox"] {
      margin: 0 !important;
    }
  }

  .sample-form1 {
    position: relative;
    width: 100%;
    max-height: 91vh;
    overflow: scroll;
    border: 1px solid #ccc;
    margin-left: 20px;
    margin-bottom: 45px;

    ///
    .each-page {
      position: relative;
      width: 900px;
      display: block;
      height: 800px;
      overflow-y: auto;
      .form-image {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
      }
      .form-fields {
        height: 800px;
        position: absolute;
        width: 850px;
        top: 0;
        left: 0;
        z-index: 100;
      }
      .page-image {
        width: 850px;
        margin: 0 auto;
      }
    }
  }

  .color-btn {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 10px;
    &.red {
      background-color: red;
    }
    &.black {
      background-color: black;
    }

    &.green {
      background-color: green;
    }
  }

  // Fields
  .form-fields-set {
    background-color: #ffffff;
    padding: 1.25em 1em;
    border-radius: 2px;
    -webkit-box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
    box-shadow: 0 0px 10px 1px rgba(0, 0, 0, 0.03);
    .el-form-item {
      margin-bottom: 0.5em;
    }
  }
}
</style>

<style lang="scss">
.view-configure-document {
  position: relative;
  padding-top: 0 !important;
  background-color: #f8f9fa;

  .error {
    color: red;
  }
  .primary {
    color: #1b487e;
  }
  .right-block {
    height: inherit;
    display: flex;
    align-items: center;
  }
}
.overlay-form {
  background-color: rgba(0, 0, 0, 0.95);
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .log-form {
    max-width: 400px;
    padding: 3.5em;
    border-radius: 5px;
    border: 1px dashed #ffffff;
    .el-form-item__label {
      color: #ffffff;
    }
    .el-input__inner {
      padding: 0 1.5em;
      line-height: 3.5em;
      height: auto;
      font-size: 1.15em;
      border-color: #8692a6;
      letter-spacing: 0.075em;
      &:hover,
      &:focus {
        border-color: #2813e0;
        background-color: #ffffff;
      }
    }
    .el-button {
      padding: 0 1.5em;
      line-height: 3.35em;
      height: auto;
      letter-spacing: 0.5px;
    }
  }
}
</style>
